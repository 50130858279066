import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridProps,
  DataGridRow,
  TableColumnDefinition,
  createTableColumn,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

export type System = {
  id: number;
  organizationID: string;
  friendlyName: string;
  description?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  powerlineFrequency: number;
};

type SystemsListProps = {
  className?: string;
  systems: System[];
  selectedSystemIds: Set<string>;
  onSelectedSystemIdsChange: (systemIds: Set<string>) => void;
};

export function SystemsList({ className, systems, selectedSystemIds, onSelectedSystemIdsChange }: SystemsListProps) {
  const { t } = useTranslation();

  // Selection
  const onSelectionChange: DataGridProps['onSelectionChange'] = (_, data) =>
    onSelectedSystemIdsChange(data.selectedItems as Set<string>);

  // Columns
  const columns: TableColumnDefinition<System>[] = [
    createTableColumn<System>({
      columnId: 'name',
      compare: (a, b) => a.friendlyName.localeCompare(b.friendlyName),
      renderHeaderCell: () => t('common.name'),
      renderCell: (system: System) => system.friendlyName,
    }),
    // createTableColumn<System>({
    //   columnId: 'cameras',
    //   compare: (a, b) => a.cameras - b.cameras,
    //   renderHeaderCell: () => t('cameras.cameras'),
    //   renderCell: (system: System) => {
    //     return system.cameras > 0 ? (
    //       <InternalLink to="/cameras">
    //         <Tooltip content={t('systems.go-to-cameras')} relationship={'label'}>
    //           <span>{system.cameras}</span>
    //         </Tooltip>
    //       </InternalLink>
    //     ) : (
    //       system.cameras
    //     );
    //   },
    // }),
    createTableColumn<System>({
      columnId: 'description',
      compare: (a, b) => (a.description ?? '').localeCompare(b.description ?? ''),
      renderHeaderCell: () => t('systems.description'),
      renderCell: (system: System) => system.description,
    }),
  ];

  return (
    <DataGrid
      className={className}
      items={systems}
      columns={columns}
      sortable
      selectionMode="multiselect"
      getRowId={(system: System) => system.id}
      focusMode="composite"
      onSelectionChange={onSelectionChange}
      selectedItems={selectedSystemIds}
    >
      <DataGridHeader>
        <DataGridRow
          selectionCell={{
            checkboxIndicator: { 'aria-label': 'Select all rows' },
          }}
        >
          {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<System>>
        {({ item, rowId }) => (
          <DataGridRow<System>
            key={rowId}
            selectionCell={{
              checkboxIndicator: { 'aria-label': 'Select row' },
            }}
          >
            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
}
