import { CodeErrorDark, CodeErrorLight } from '@axiscommunications/fluent-illustrations';
import { bundleIllustrationSmart } from '@axiscommunications/fluent-illustrations';
import { makeStyles } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { EmptyPage } from '../components/EmptyPage';

const useStyles = makeStyles({
  container: {
    height: '100vh',
  },
});

type InternalErrorPageProps = {
  description?: string;
};
function InternalErrorPage({ description }: InternalErrorPageProps) {
  const styles = useStyles();
  const { t } = useTranslation();
  const title = t('common.internal-error');

  return (
    <div className={styles.container}>
      <EmptyPage
        title={title}
        description={description}
        Illustration={bundleIllustrationSmart(CodeErrorDark, CodeErrorLight)}
      />
    </div>
  );
}

export { InternalErrorPage };
