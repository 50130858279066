import { Spinner, makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    top: '50%',
    transform: 'translate(-50%)',
    left: '50%',
  },
});

export function LoadingPage() {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
}
