import { NoSitesDark, NoSitesLight, bundleIllustrationSmart } from '@axiscommunications/fluent-illustrations';
import { Card, makeStyles, tokens } from '@fluentui/react-components';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetSystems } from '../client/systems';
import { EmptyPage } from '../components/EmptyPage';
import { ListController } from '../components/ListController';
import { LoadingPage } from '../components/Loading';
import { System, SystemsList } from '../components/SystemsList';
import { SystemsListHeader } from '../components/SystemsListHeader';
import { SystemsWizard } from '../components/SystemsWizard';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: tokens.spacingVerticalXL,
  },
  card: {
    width: '100%',
    marginTop: tokens.spacingVerticalL,
  },

  // List header
  header: {
    width: '100%',
  },

  // List
  systemsList: {
    flexGrow: 1,
  },

  // List controller
  listController: {
    width: '100%',
    marginTop: `calc(-1 * ${tokens.spacingVerticalMNudge})`,
  },
});

export default function SystemsPage() {
  const { t } = useTranslation();
  const params = useParams();
  const styles = useStyles();

  const [visibleSystems, setVisibleSystems] = useState<System[]>([]);

  const title = t('systems.get-started');
  const description = t('systems.you-need-a-system');
  const navigate = useNavigate();

  const handleOpenChange = useCallback((open: boolean) => navigate(open ? '/systems/create' : '/systems'), [navigate]);

  const { /* error, */ fetching, systems, reexecuteGetSystems } = useGetSystems();
  const defaultOpen = params.what === 'create';

  const [selectedSystemIds, setSelectedSystemIds] = useState<Set<string>>(new Set([]));
  const handleVisibleDataChange = useCallback((data: object) => setVisibleSystems(data as System[]), []);

  if (fetching) {
    return <LoadingPage />;
  }

  // Wizard
  if (systems.length === 0) {
    return (
      <EmptyPage
        title={title}
        description={description}
        Illustration={bundleIllustrationSmart(NoSitesDark, NoSitesLight)}
      >
        <SystemsWizard defaultOpen={defaultOpen} onOpenChange={handleOpenChange} onAddSystem={reexecuteGetSystems} />
      </EmptyPage>
    );
  }

  // Systems list
  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <SystemsListHeader
          className={styles.header}
          systems={systems}
          selectedSystemIds={selectedSystemIds}
          onDeselectSystems={() => setSelectedSystemIds(new Set([]))}
          onDeleteSystems={() => console.log('delete systems')}
        >
          <SystemsWizard defaultOpen={defaultOpen} onOpenChange={handleOpenChange} onAddSystem={reexecuteGetSystems} />
        </SystemsListHeader>

        <SystemsList
          className={styles.systemsList}
          systems={visibleSystems}
          selectedSystemIds={selectedSystemIds}
          onSelectedSystemIdsChange={setSelectedSystemIds}
        />

        <ListController
          className={styles.listController}
          data={systems}
          nrOfRowsOptions={[25, 50, 100]}
          onVisibleDataChange={handleVisibleDataChange}
        />
      </Card>
    </div>
  );
}
