import { Link } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type InternalLinkProps = PropsWithChildren<{ to: string }>;
export function InternalLink({ to, children }: InternalLinkProps) {
  return (
    <RouterLink to={to}>
      <Link>{children}</Link>
    </RouterLink>
  );
}
