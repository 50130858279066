import { DevicesDark, DevicesLight, bundleIllustrationSmart } from '@axiscommunications/fluent-illustrations';
import { useTranslation } from 'react-i18next';

import { EmptyPage } from '../components/EmptyPage';
import { InternalLink } from '../components/InternalLink';

export default function CamerasPage() {
  const { t } = useTranslation();
  const title = t('cameras.psst');
  const description = t('cameras.before-you-can-start');
  const link = t('cameras.go-create-a-system');

  return (
    <EmptyPage
      title={title}
      description={description}
      Illustration={bundleIllustrationSmart(DevicesDark, DevicesLight)}
    >
      <InternalLink to="/systems/create">{link}</InternalLink>
    </EmptyPage>
  );
}
