import { AuthStatus, useAuth } from '@axteams-one/auth-provider';
import { useEffect } from 'react';

function useRedirectIfNotLoggedIn() {
  const { login, status } = useAuth();

  useEffect(() => {
    if (status === AuthStatus.Uninitialized) {
      return;
    }

    if (status !== AuthStatus.Authenticated) {
      if (window.location.href) {
        window.localStorage.setItem('auth:redirect:url', window.location.pathname);
      }
      login();
    }
  }, [login, status]);
}

export { useRedirectIfNotLoggedIn };
