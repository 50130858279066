import { usePopulatedTopbarValues } from '@axteams-one/populated-topbar';
import { useEffect, useState } from 'react';

export function useGetCurrentOrganizationLabel() {
  const { organization, loaded, organizations } = usePopulatedTopbarValues();
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (!loaded) {
      return;
    }
    if (!organization) {
      return;
    }
    if (!organizations) {
      return;
    }

    const organizationWithLabel = organizations.find((o) => o.id === organization.id);
    if (!organizationWithLabel) {
      return;
    }

    setLabel(organizationWithLabel.label);
  }, [organization, loaded, organizations]);

  return label;
}
