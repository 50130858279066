import { CreateDataDark, CreateDataLight, bundleIllustrationSmart } from '@axiscommunications/fluent-illustrations';
import { Body1, Field, Input, makeStyles, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  illustration: {
    height: '120px',
    marginTop: tokens.spacingVerticalL,
    marginBottom: tokens.spacingVerticalL,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '380px',
    marginTop: tokens.spacingVerticalXL,
  },
});

type NameProps = {
  name: string;
  onChange: (name: string) => void;
};
export function Name({ name, onChange }: NameProps) {
  const styles = useStyles();
  const { t } = useTranslation();

  const Illustration = bundleIllustrationSmart(CreateDataDark, CreateDataLight);

  return (
    <div className={styles.container}>
      <Illustration className={styles.illustration} />

      <Body1>{t('systems.choose-a-clear-name')}</Body1>
      <div className={styles.inputContainer}>
        <Field label={t('systems.system-name')} required>
          <Input value={name} onChange={(_, { value }) => onChange(value)} />
        </Field>
      </div>
    </div>
  );
}
