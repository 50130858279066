import { PropsWithChildren } from 'react';
import { Provider } from 'urql';

import { LoadingPage } from '../components/Loading';
import { Api, useUrqlClient } from '../hooks/useUrqlClient';

export function BwmcApi({ children }: PropsWithChildren) {
  const client = useUrqlClient(Api.Bwmc);

  if (!client) {
    return <LoadingPage />;
  }

  return <Provider value={client}>{children}</Provider>;
}
