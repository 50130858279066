import { CreateSystemInput, useCreateSystemMutation, useGetSystemsQuery } from '../graphql/__generated__/graphql';
import { useCurrentOrganization } from '../providers/CurrentOrganizationProvider';

export function useCreateSystem() {
  const [result, executeMutation] = useCreateSystemMutation();
  if (result.error) {
    throw result.error;
  }
  return (options: CreateSystemInput) => executeMutation({ input: options });
}

export function useGetSystems() {
  const { id: organizationId } = useCurrentOrganization();

  const [result, reexecuteGetSystems] = useGetSystemsQuery({
    requestPolicy: 'network-only',
    variables: { options: { organizationId } },
  });

  const error = !!result.error;
  const fetching = result.fetching;
  const systems = result.data?.getSystems ?? [];

  return { fetching, error, systems, reexecuteGetSystems };
}
