import gql from 'graphql-tag';
import * as Urql from 'urql';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Object: { input: any; output: any; }
  Product: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
};

/** Options to the createSystem mutation. */
export type CreateSystemInput = {
  /** (Optional) Address of the system. */
  address?: InputMaybe<Scalars['String']['input']>;
  /** (Optional) Free text input for adding a description to the system. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name for the system */
  friendlyName: Scalars['String']['input'];
  /** (Optional) Latitude and longitude for the system, to display it on a map, as well as geo-lookup. */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The id of the organization the system should be created in.
   * Also used for authentication.
   */
  organizationID: Scalars['String']['input'];
  /** Powerline frequency at the systems location. either 50 or 60 (hz). */
  powerlineFrequency: Scalars['Int']['input'];
  /** The IAM resource group in which the system will be created. */
  resourceParent: Scalars['String']['input'];
};

/** ErrorCode describes all the available error codes. */
export enum ErrorCode {
  /** The request is badly formed or otherwise incorrect. */
  BwmcBadRequest = 'BWMC_BAD_REQUEST',
  /** The query or mutation is not implemented. */
  BwmcNotImplemented = 'BWMC_NOT_IMPLEMENTED',
  /** The organization does not exist. */
  BwmcOrganizationNotFound = 'BWMC_ORGANIZATION_NOT_FOUND',
  /**
   * The request did not meet its preconditions. This can occur if changes were
   * made to a resource in parallel to the request, causing a collision.
   */
  BwmcPreconditionFailed = 'BWMC_PRECONDITION_FAILED',
  /** A BWS system used in a query or mutation was not found. */
  BwmcSystemNotFound = 'BWMC_SYSTEM_NOT_FOUND',
  /** The requesting party has issued too many requests. */
  BwmcTooManyRequests = 'BWMC_TOO_MANY_REQUESTS',
  /** The requesting party is unauthorized to fulfill the request. */
  BwmcUnauthorized = 'BWMC_UNAUTHORIZED',
  /** An internal server error occurred. */
  BwmcUnknownError = 'BWMC_UNKNOWN_ERROR'
}

/** Options to the getSystems query. */
export type GetSystemsOptions = {
  /** The id of the organization. */
  organizationId: Scalars['String']['input'];
};

export type License = {
  __typename?: 'License';
  /** The timestamp when this type of license expires. */
  expires: Scalars['Timestamp']['output'];
  /** The type of the license (annual, trial, demo). */
  type: LicenseType;
};

export type LicenseInfo = {
  __typename?: 'LicenseInfo';
  /**
   * Licensed is true if the organization has purchased enough licenses or there is a valid trial/demo
   * period in progress.
   */
  licensed: Scalars['Boolean']['output'];
  /** Licenses contains all the types of licenses this organization has had. */
  licenses: Array<License>;
  /** The type of product this license is valid for. */
  product: Scalars['Product']['output'];
};

export enum LicenseType {
  Annual = 'ANNUAL',
  Demo = 'DEMO',
  Trial = 'TRIAL'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a new BWMC System and assign it to the IAM Organization. */
  createSystem?: Maybe<System>;
  /** Remove a preexisting BWMC System. Returns id of the removed system */
  removeSystem?: Maybe<Scalars['Int']['output']>;
  /** Edit a preexisting BWMC System. */
  updateSystem?: Maybe<System>;
};


export type MutationCreateSystemArgs = {
  input: CreateSystemInput;
};


export type MutationRemoveSystemArgs = {
  input: RemoveSystemInput;
};


export type MutationUpdateSystemArgs = {
  input: UpdateSystemInput;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches the license information for the organization. */
  getLicenseInfo: LicenseInfo;
  /** Retrieves all BWMC systems within an organization that the user has access to. */
  getSystems: Array<System>;
};


export type QueryGetLicenseInfoArgs = {
  organizationId: Scalars['String']['input'];
  product: Scalars['Product']['input'];
};


export type QueryGetSystemsArgs = {
  options: GetSystemsOptions;
};

/** Options to the removeSystem mutation. */
export type RemoveSystemInput = {
  /**
   * The id of the organization the system should be created in.
   * Also used for authentication.
   */
  organizationID: Scalars['String']['input'];
  /** The new IAM resource group in which the system will be removed from. */
  resourceParent: Scalars['String']['input'];
  /** The Database id of the system you want to remove */
  systemID: Scalars['Int']['input'];
};

export type StartTrialInput = {
  /** The id of the organization. */
  organizationId: Scalars['String']['input'];
  /** The product to start a Trial for. */
  product: Scalars['Product']['input'];
};

/** The System type represents a BWMC System. */
export type System = {
  __typename?: 'System';
  /** Address for the system. */
  address?: Maybe<Scalars['String']['output']>;
  /** Description for the system. */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the system. */
  friendlyName: Scalars['String']['output'];
  /** The id of the entity representing the system in db. */
  id: Scalars['Int']['output'];
  /** Latitude for the system. */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Longitude for the system. */
  longitude?: Maybe<Scalars['Float']['output']>;
  /**
   * The id of the organization the system should be created in.
   * Also used for authentication.
   */
  organizationID: Scalars['String']['output'];
  /** Powerline frequency at the physical location of the system. */
  powerlineFrequency: Scalars['Int']['output'];
  /** The IAM resource group in which the system exists. */
  resourceParent: Scalars['String']['output'];
};

/** Options to the updateSystem mutation. */
export type UpdateSystemInput = {
  /** (Optional) Address of the system. */
  address?: InputMaybe<Scalars['String']['input']>;
  /** (Optional) Free text input for adding a description to the system. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name for the system */
  friendlyName: Scalars['String']['input'];
  /** (Optional) Latitude and longitude for the system, to display it on a map, as well as geo-lookup. */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The id of the organization the system should be created in.
   * Also used for authentication.
   */
  organizationID: Scalars['String']['input'];
  /** Powerline frequency at the systems location. either 50 or 60 (hz). */
  powerlineFrequency: Scalars['Int']['input'];
  /** The new IAM resource group in which the system will be moved to. */
  resourceParent: Scalars['String']['input'];
  /** DatabaseId for the system you want to affect. */
  systemID: Scalars['Int']['input'];
};

export type CreateSystemMutationVariables = Exact<{
  input: CreateSystemInput;
}>;


export type CreateSystemMutation = {
  __typename?: 'Mutation',
  createSystem?: {
    __typename?: 'System',
    id: number,
    organizationID: string,
    friendlyName: string,
    description?: string | null,
    address?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    powerlineFrequency: number
  } | null
};

export type GetSystemsQueryVariables = Exact<{
  options: GetSystemsOptions;
}>;


export type GetSystemsQuery = {
  __typename?: 'Query',
  getSystems: Array<{
    __typename?: 'System',
    id: number,
    organizationID: string,
    friendlyName: string,
    description?: string | null,
    address?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    powerlineFrequency: number
  }>
};


export const CreateSystemDocument = gql`
  mutation createSystem($input: CreateSystemInput!) {
    createSystem(input: $input) {
      id
      organizationID
      friendlyName
      description
      address
      latitude
      longitude
      powerlineFrequency
    }
  }
`;

export function useCreateSystemMutation() {
  return Urql.useMutation<CreateSystemMutation, CreateSystemMutationVariables>(CreateSystemDocument);
};
export const GetSystemsDocument = gql`
  query getSystems($options: GetSystemsOptions!) {
    getSystems(options: $options) {
      id
      organizationID
      friendlyName
      description
      address
      latitude
      longitude
      powerlineFrequency
    }
  }
`;

export function useGetSystemsQuery(options: Omit<Urql.UseQueryArgs<GetSystemsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSystemsQuery, GetSystemsQueryVariables>({ query: GetSystemsDocument, ...options });
};
