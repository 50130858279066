import { DiscoveryProvider } from '@axteams-one/bws-cloud-discovery/react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { LoadingPage } from './components/Loading';
import { config, isDev, setConfigFromEnvironment } from './config';
import { App } from './pages/App';
import CamerasPage from './pages/CamerasPage';
import { InternalErrorPage } from './pages/InternalErrorPage';
import SystemsPage from './pages/SystemsPage';
import './pages/reset.css';
import { ThemeProvider } from './providers/ThemeProvider';
import { AuthenticatedRoute } from './util/AuthenticatedRoute';

showConsoleMotd();
main();

function main() {
  setConfigFromEnvironment();
  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);

  const app = (
    <AuthenticatedRoute>
      <App />
    </AuthenticatedRoute>
  );

  root.render(
    <ThemeProvider cache={localStorage}>
      <DiscoveryProvider
        endpoint={config.discoveryApiUrl}
        errorPlaceholder={<InternalErrorPage />}
        loadingPlaceholder={<LoadingPage />}
      >
        <Suspense fallback={<LoadingPage />}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={app}>
                <Route path="/cameras" element={<CamerasPage />} />
                <Route path="/systems/:what?" element={<SystemsPage />} />
              </Route>
              <Route path="*" element={<Navigate to="/systems" />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </DiscoveryProvider>
    </ThemeProvider>
  );
}

function showConsoleMotd() {
  if (isDev(window.location.hostname)) {
    return;
  }
  console.log('%cWarning', 'color:red;font-size:2rem');
  console.log(`This is a browser feature meant for developers.
If you have been told to copy and paste something here you may be the target of fraud.
For more information please refer to https://en.wikipedia.org/wiki/Self-XSS.

For security-related inquiries, please refer to https://www.axis.com/security.txt.`);

  console.log(`Application version: ${import.meta.env['VITE_APP_VERSION_LONG'] || 'unknown'}.`);
}
