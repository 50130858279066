import { Button, Tag, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { Delete20Filled } from '@fluentui/react-icons';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { System } from '../components/SystemsList';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns:
      '[wizard] auto [divider] auto [nrOfSystems] auto 1fr [columnOptions] auto [filter] auto [search] auto',
  },

  divider: {
    width: '1px',
    height: '16px',
    backgroundColor: tokens.colorNeutralForeground2,
    marginInline: tokens.spacingHorizontalS,
  },

  wizard: {
    gridColumnStart: 'wizard',
    marginRight: tokens.spacingHorizontalS,
  },

  nrOfSystems: {
    gridColumnStart: 'nrOfSystems',
    marginLeft: tokens.spacingHorizontalS,
  },

  selectedSystemsTag: {
    marginRight: tokens.spacingHorizontalS,
  },
});

type SystemsListHeaderProps = {
  className?: string;
  systems: System[];
  selectedSystemIds: Set<string>;
  onDeselectSystems: () => void;
  onDeleteSystems: () => void;
};

export function SystemsListHeader({
  className,
  systems,
  selectedSystemIds,
  onDeselectSystems,
  onDeleteSystems,
  children,
}: PropsWithChildren<SystemsListHeaderProps>) {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={mergeClasses(styles.container, className)}>
      {selectedSystemIds.size === 0 ? (
        /* No system(s) selected */
        <>
          <div className={styles.wizard}>{children}</div>
          <div className={styles.divider}></div>
          <span className={styles.nrOfSystems}>
            {systems.length} {t('systems.systems')}
          </span>
        </>
      ) : (
        /* Systems selected */
        <>
          <Tag
            className={styles.selectedSystemsTag}
            dismissible
            dismissIcon={{ 'aria-label': 'remove' }}
            onClick={onDeselectSystems}
          >
            {`${selectedSystemIds.size} ${t('common.selected')}`}
          </Tag>
          <div className={styles.divider}></div>
          <Button icon={<Delete20Filled />} appearance="subtle" onClick={onDeleteSystems} />
        </>
      )}
    </div>
  );
}
