import { useNavigationStyles, useTabStyles } from '@axiscommunications/fluent-styles';
import { Tab, TabList, Text, Tooltip, makeStyles, mergeClasses } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  tooltipText: {
    fontWeight: 'semibold',
    textTransform: 'capitalize',
  },
  tabList: {
    height: 'initial',
  },
});

function Sidebar({ children }: PropsWithChildren) {
  const location = useLocation();
  const selectedValue = location.pathname.split('/')[1];
  const navStyles = useNavigationStyles();
  const styles = useStyles();
  const className = mergeClasses(navStyles.tabList, styles.tabList);

  return (
    <TabList className={className} selectedValue={selectedValue} size="large" vertical>
      {children}
    </TabList>
  );
}

type SidebarItemProps = {
  url: string;
  tooltipText: string;
  icon: JSX.Element;
};

function SidebarItem({ url, tooltipText, icon }: SidebarItemProps) {
  const location = useLocation();
  const selected = url === location.pathname.split('/')[1];

  const { rootStyle } = useTabStyles({ selected });
  const styles = useStyles();

  return (
    <Tooltip
      content={<Text className={styles.tooltipText}>{tooltipText}</Text>}
      positioning="after"
      relationship="label"
      withArrow
    >
      <NavLink to={url}>
        <Tab icon={icon} value={url} className={rootStyle} data-testid={`${url}SidebarTab`} />
      </NavLink>
    </Tooltip>
  );
}

export { Sidebar, SidebarItem };
