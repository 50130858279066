import { AxisIllustrationProps } from '@axiscommunications/fluent-illustrations';
import { Body2, Title3, makeStyles, tokens } from '@fluentui/react-components';
import { FC, PropsWithChildren, ReactElement } from 'react';

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr [content] auto 2fr',
    gridTemplateColumns: '1fr [content] 450px 1fr',
  },
  content: {
    gridColumnStart: 'content',
    gridRowStart: 'content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: tokens.spacingVerticalL,
    color: tokens.colorNeutralForeground2,
  },
  illustration: {
    height: '160px',
  },
  center: {
    textAlign: 'center',
  },
});

type EmptyPageProps = {
  title: string;
  description?: string | ReactElement;
  Illustration: FC<AxisIllustrationProps>;
};
export function EmptyPage({ title, description, Illustration, children }: PropsWithChildren<EmptyPageProps>) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Illustration className={styles.illustration} />
        <Title3>{title}</Title3>
        {description && <Body2 className={styles.center}>{description}</Body2>}
        {children}
      </div>
    </div>
  );
}
