export type Environment = 'dev' | 'stage' | 'prod';

interface Config {
  acxClientKey: string;
  discoveryApiUrl: string;
  environment: Environment;
  loginUrl: string;
  region: string;
  regionApiEndpoint: string;
  userAccountUrl: string;
}

type EnvironmentRecord = Record<Environment, Config>;

const environments: EnvironmentRecord = {
  dev: {
    acxClientKey: '3138af13f04691370ff277e4fda421e0',
    discoveryApiUrl: 'https://discovery.api.dev.bodyworn.axis.com/v1/discovery/hostingInstances/dev',
    environment: 'dev',
    loginUrl: 'https://stage.login.connect.axis.com',
    region: 'dev',
    regionApiEndpoint: 'https://api.az.bws.axis.cloud/v1/regions',
    userAccountUrl: 'https://auth-stage.axis.com/user-center/account',
  },
  stage: {
    acxClientKey: '3138af13f04691370ff277e4fda421e0',
    discoveryApiUrl: 'https://discovery.api.stage.bodyworn.axis.com/v1/discovery/hostingInstances/stage',
    environment: 'stage',
    loginUrl: 'https://stage.login.connect.axis.com',
    region: 'stage',
    regionApiEndpoint: 'https://api.bodyworn-stage.axis.cloud/v1/regions',
    userAccountUrl: 'https://auth-stage.axis.com/user-center/account',
  },
  prod: {
    acxClientKey: '7cb7db02c80cfcee62c5fded3b23578b',
    discoveryApiUrl: 'https://discovery.api.prod.bodyworn.axis.com/v1/discovery/hostingInstances/prod',
    environment: 'prod',
    loginUrl: 'https://eu.login.connect.axis.com',
    region: 'de',
    regionApiEndpoint: 'https://api.bodyworn.axis.cloud/v1/regions',
    userAccountUrl: 'https://auth.axis.com/user-center/account',
  },
};

export let config = {} as Config;

export function setConfigFromEnvironment() {
  const environment = getEnvironment(window.location.hostname);
  config = environments[environment];
}

export function isDev(hostname: string) {
  return (
    hostname === 'localhost' ||
    hostname === '127.0.0.1' ||
    hostname.endsWith('local.bodyworn.axis.com') ||
    hostname.endsWith('.azurestaticapps.net') // PR previews
  );
}

function getEnvironment(hostname: string): Environment {
  const prod = (hostname.endsWith('bw.mysystems.axis.com') || hostname.endsWith('prod.bodyworn.axis.com')) && 'prod';
  const stage = hostname.endsWith('stage.bodyworn.axis.com') && 'stage';
  const dev = hostname.endsWith('dev.bodyworn.axis.com') && 'dev';
  const local = isDev(hostname) && 'dev';
  const environment = prod || stage || dev || local;
  if (!environment) {
    throw new Error('Unknown environment');
  }
  return environment;
}
