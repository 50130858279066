import { BodyWornCameraMultiple20Filled } from '@axiscommunications/fluent-icons';
import { Language, LanguageCode, PopulatedTopbarProvider } from '@axteams-one/populated-topbar';
import React, { ReactElement, useCallback } from 'react';

import { config } from '../config';
import i18n from '../i18n/i18n';
import { useThemeId } from './ThemeProvider';

type TopBarProviderProps = {
  children: ReactElement;
};

export const TopbarProvider = ({ children }: TopBarProviderProps): ReactElement => {
  const [_, setThemeId] = useThemeId();
  const onLanguageChanged = useCallback((lang: Language) => {
    // TODO Support for right->left languages
    void i18n.changeLanguage(lang);
  }, []);

  const env = config.environment === 'prod' ? 'prod' : 'stage';

  return (
    <PopulatedTopbarProvider
      env={env}
      region="eu"
      acxApiKey={config.acxClientKey}
      applicationLubalinPath="/LubalinGraphECG-Demi.woff2"
      myApplication={{
        id: 'bwmc',
        label: 'Manager Center',
        group: 'AXIS_BODY_WORN',
        icon: <BodyWornCameraMultiple20Filled />,
      }}
      onLanguageChanged={onLanguageChanged}
      onThemeChanged={setThemeId}
      supportedLanguages={[LanguageCode.English]}
    >
      {children}
    </PopulatedTopbarProvider>
  );
};
