import { AuthStatus, TokenResponse, useAuth } from '@axteams-one/auth-provider';
import { API } from '@axteams-one/bws-cloud-discovery';
import { AuthConfig, AuthUtilities, authExchange } from '@urql/exchange-auth';
import { useEffect, useState } from 'react';
import { Client, cacheExchange, createClient, fetchExchange } from 'urql';

export enum Api {
  Bwmc,
}

// This should come from discovery api at some point
const bwmcApi: API = {
  id: 'bwmc',
  name: 'BWMC',
  uri: 'http://localhost:3001/bwmc-api/v1/query',
};

function useUrqlClient(api: Api) {
  if (api !== Api.Bwmc) {
    throw new Error('Unsupported API');
  }

  const { refresh, status } = useAuth();
  const [urqlClient, setUrqlClient] = useState<Client>();

  useEffect(() => {
    if (status === AuthStatus.Uninitialized) {
      return;
    }

    const client = createClient({
      url: bwmcApi.uri,
      exchanges: [cacheExchange, getAuthExchange(refresh), fetchExchange],
    });

    setUrqlClient(client);
  }, [api, bwmcApi, refresh, status]);

  return urqlClient;
}

function getAuthExchange(refresh: () => Promise<TokenResponse>) {
  return authExchange(getAuthExchangeConfig(refresh));
}

function getAuthExchangeConfig(refresh: () => Promise<TokenResponse>): (utils: AuthUtilities) => Promise<AuthConfig> {
  return async (utils) => {
    const response = await refresh();
    let token = response.data?.access_token;

    return {
      addAuthToOperation(operation) {
        if (!token) {
          return operation;
        }
        return utils.appendHeaders(operation, {
          Authorization: `Bearer ${token}`,
        });
      },
      didAuthError() {
        return true;
      },
      async refreshAuth() {
        const response = await refresh();
        token = response.data?.access_token;
      },
    };
  };
}

export { useUrqlClient };
